<template>
    <div class="user-wrapper">
        <div class="container">
            <a-row style="margin-top:24px;margin-left: 31px;font-size: 18px;font-weight: 500;color: #333333;">
                <a-col :span="24"><span>订单信息</span></a-col>
            </a-row>
            <div class="order_info">
                <a-row class="order_head">
                    <a-col :span="6"><span>下单时间: {{orderInfo.create_at}}</span></a-col>
                    <a-col :span="12" style="text-align:left;"><span>订单编号:{{orderInfo.order_no}}</span></a-col>
                    <a-col :span="6" style="text-align:right;" v-if="orderInfo.status==0">
                        <img src="@/assets/images/web/music/order_payment.svg" style="width:18px;" />
                        <span style="color: #FF7B13;"> 待支付 </span>
                    </a-col>
                    <a-col :span="6" style="text-align:right;" v-if="orderInfo.status==2||orderInfo.status==1">
                        <img src="@/assets/images/web/music/order_cancel.svg" style="width:18px;" />
                        <span style="color: #999999;"> 已取消 </span>
                    </a-col>
                    <a-col :span="6" style="text-align:right;" v-if="orderInfo.status==3">
                        <img src="@/assets/images/web/music/order_paying.svg" style="width:18px;" />
                        <span style="color: #FF7B13;"> 支付中 </span>
                    </a-col>
                    <a-col :span="6" style="text-align:right;" v-if="orderInfo.status==4">
                        <img src="@/assets/images/web/music/order_done.svg" style="width:18px;" />
                        <span style="color:#46B1A7;"> 已完成 </span>
                    </a-col>
                </a-row>
                <a-row class="desc">
                    <a-col :span="10">
                        <a-col :md="6" :xs="12" style="line-height:36px;padding-left:24px;">
                            <a-col :span="24">总价： </a-col>
                            <a-col :span="24">优惠券：</a-col>
                            <a-col :span="24">实付： </a-col>
                        </a-col>
                        <a-col :md="18" xs="12" style="text-align:left;line-height:36px;">
                            <a-col :span="24">¥ {{orderInfo.total_amount}}</a-col>
                            <a-col :span="24">¥ {{orderInfo.discount_amount}}</a-col>
                            <a-col :span="24">¥ {{orderInfo.pay_amount}}</a-col>
                        </a-col>
                    </a-col>
                    <a-col :span="14" style="line-height:28px;padding-left:20%;text-align:center;font-weight:500;">
                        <a-col :span="24"><span style="color: #999999;">请向客服提交订单号和付款凭证截图，我们会帮助完成订单</span></a-col>
                        <a-col :span="24"><span>账户名称</span><span>北京火星火翎文化科技有限公司</span></a-col>
                        <a-col :span="24"><span>银行账户</span><span>100010124824</span></a-col>
                        <a-col :span="24"><span>开户行</span><span>友利银行（中国）有限公司</span></a-col>
                    </a-col>
                </a-row>
                <a-row class="order_bottom" v-if="order_type==1">
                    <a-col :span="24"><span>商品信息</span></a-col>
                </a-row>
                <a-row class="bottom_desc" v-if="order_type==1">
                    <a-col v-for="(cItem, key) in orderInfo.detail" :key="key" :span="24" style="height:114px;">
                        <a-col :span="4"><img :src="cItem.logo_url"
                                style="width:90px;height:90px;border-radius: 2px;" /></a-col>
                        <a-col :span="6" style="line-height:30px;">
                            <a-col :span="24" class="ellipsis"><span
                                    style="font-weight: 600;color: #333333;font-size: 16px;">{{cItem.music_name}}</span>
                            </a-col>
                            <a-col :span="24" class="ellipsis">{{cItem.suplr_name}}</a-col>
                        </a-col>
                        <a-col :span="8">
                            <a-col :span="24">
                                <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">{{$t('music.labelUseMethod')}}:
                                </a-col>
                                <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.use_mode}}</a-col>
                            </a-col>
                            <a-col :span="24">
                                <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">{{$t('music.labelUseScene')}}:</a-col>
                                <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_scene | toAuthScene}}
                                </a-col>
                            </a-col>
                            <a-col :span="24">
                                <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">{{$t('music.labelAuthArea')}}:</a-col>
                                <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_area | toArea}}</a-col>
                            </a-col>
                            <a-col :span="24">
                                <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">{{$t('music.labelAuthTime')}}:</a-col>
                                <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_time | toAuthPeriod}}
                                </a-col>
                            </a-col>
                        </a-col>
                        <a-col :span="6" style="line-height:90px;"><span
                                style="font-weight: 400;color: #E30D0D;font-size:16px;">¥ {{cItem.price}}</span></a-col>
                    </a-col>
                </a-row>
                <div style="margin-top:24px;font-size: 18px;font-weight: 500;color: #333333;" v-if="order_type==2 || order_type==3">商品信息
                </div>
                <div v-if="order_type==2">
                    <a-row v-for="(scope, key) in dataTableList" :key="key" style="margin-top: 16px;">
                        <a-col :span="24" class="list-header"
                            style="line-height:40px;background: #F4F6F6;border: 1px solid #EBF0F2;padding-left: 1%;height:40px;">
                            <a-col span="4"><span>商品类型</span></a-col>
                            <a-col span="2"><span>时长</span></a-col>
                            <a-col span="4"><span>下单时间</span></a-col>
                            <a-col span="7">
                                <span>权益说明</span>
                            </a-col>

                            <a-col span="3"><span>付款方式</span></a-col>
                            <a-col span="4"><span>价格</span></a-col>
                        </a-col>
                        <a-col :span="24"
                            style="height:130px;border: 1px solid #EBF0F2;display:flex;align-items:center;padding-left: 1%;background-color: #FFFFFF;">
                            <a-col :span="4">
                                <img src="@/assets/images/web/member/个人vip.png" v-if="vip_info.auth_type==2" alt="个人VIP">
                                <img src="@/assets/images/web/member/企业VIP.png" v-if="vip_info.auth_type==1" alt="企业VIP">
                                <img src="@/assets/images/web/member/省钱卡.png" v-if="vip_info.auth_type==3" alt="省钱卡">
                            </a-col>
                            <a-col :span="2">
                                <span v-if="vip_info.vip_type==1">月度</span>
                                <span v-if="vip_info.vip_type==2">季度</span>
                                <span v-if="vip_info.vip_type==3">年度</span>
                                <span v-if="vip_info.vip_type==4">定制</span>
                            </a-col>
                            <a-col :span="4">
                                <span>{{vip_info.coop_start_date}}</span>
                            </a-col>
                            <a-col :span="7" style="text-align:left;">
                                <a-col :span="24" v-if="vip_info.auth_type==3">
                                    <div v-if="vip_info.vip_type==1" style="width: 80%;">当月立即获得零售曲库8折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用）</div>
                                    <div v-if="vip_info.vip_type==3" style="width: 80%;">每月获得零售曲库6折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用）</div>
                                </a-col>
                                <a-col :span="24" v-else>
                                    <p v-if="vip_info.vip_type==4">曲库类型：全部曲库</p>
                                    <p v-if="vip_info.vip_type!=4">选曲范围：会员专区+音效库</p>
                                    <p>下载限制：<span v-if="vip_info.download_num == -1">不限量/天</span><span v-else>{{vip_info.download_num}}</span></p>
                                    <p v-if="vip_info.auth_type==1">授权说明：企业商用</p>
                                    <p v-if="vip_info.auth_type==2">授权说明：个人（非商用）</p>
                                </a-col>
                            </a-col>

                            <a-col :span="3">{{payway}}

                            </a-col>
                            <a-col :span="4">
                                <span style="color:#E30D0D;">¥{{orderInfo.total_amount}}.00</span>
                            </a-col>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="order_type==3">
                    <a-row v-for="(scope, key) in dataTableList" :key="key" style="margin-top: 16px;">
                        <a-col :span="24" class="list-header"
                            style="line-height:40px;background: #F4F6F6;border: 1px solid #EBF0F2;padding-left: 1%;height:40px;">
                            <a-col span="3"><span>商品类型</span></a-col>
                            <a-col span="3"><span>套餐名称</span></a-col>
                            <a-col span="4"><span>授权范围</span></a-col>
                            <a-col span="2"><span>授权数量</span></a-col>
                            <a-col span="3"><span>下载数量</span></a-col>
                            <a-col span="3"><span>下单时间</span></a-col>
                            <a-col span="3"><span>付款方式</span></a-col>
                            <a-col span="3"><span>价格</span></a-col>
                        </a-col>
                        <a-col :span="24"
                            style="height:142px;border: 1px solid #EBF0F2;display:flex;align-items:center;padding-left: 1%;background-color: #FFFFFF;">
                            <a-col :span="3">
                                <div style="width: 82px;
                                height: 34px;text-align: center;line-height: 34px;color:white;
                                background: linear-gradient(132deg, #73A2BE 0%, #578AA9 100%);
                                border-radius: 6px;">超值套餐</div>
                            </a-col>
                            <a-col :span="3"><span>{{orderInfo.goods_name}}</span></a-col>
                            <a-col :span="4" style="text-align:left; padding-top: 14px;">
                                <a-col :span="24" >
                                    <p>授权数量：{{orderInfo.auth_num}}首</p>
                                    <p>授权用途：{{scene}}</p>
                                    <p>授权地域：{{area}}</p>
                                    <p>授权期限：{{authTime}}</p>
                                </a-col>
                            </a-col>
                            <a-col :span="2">{{orderInfo.auth_num}}首</a-col>
                            <a-col :span="3">{{orderInfo.download_num}}首</a-col>
                            <a-col :span="3"><span>{{orderInfo.create_at}}</span></a-col>

                            <a-col :span="3">{{payway}}</a-col>
                            <a-col :span="3">
                                <span style="color:#E30D0D;">¥{{orderInfo.total_amount}}.00</span>
                            </a-col>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                orderInfo: {},
                order_id: this.$route.params.order_id,
                order_type: this.$route.params.type,
                vip_info:{},
                dataTableList: [{}],
                payWayList:["","微信","支付宝","公对公"],
                payway:"",
                sceneList:["全场景", "连续剧/综艺", "商业广告/宣传片", "自媒体视频", "电影/动画/纪录片", "游戏", "APP内置音乐", "在线教育/网络直播", "线下/活动/硬件", "个人用途(非商用)"],
                scene:"",
                authTimeList:["一年", "永久"],
                authTime:"",
                areaList:["中国大陆", "中国(含港澳台)", "全球"],
                area:"",
            }
        },
        created() {
            let order_id = this.$route.params.order_id || '';

            this.order_id = order_id;
            this.getOrderDetail({ order_id })
        },

        components: {
        },
        methods: {
            
            getOrderDetail(params) {
                if (this.order_type == 1){ //普通订单
                    this.$axios.OrderInfo(params).then(res => {
                        const data = res.data;
                        if (data && data.code == 0) {
                            this.orderInfo = data.data || {};
                        }
                    })
                }else if(this.order_type == 2){//会员订单
                    this.$axios.viporderInfo(params).then(res => {
                        // console.log(res.data);
                        if(res.data.code == 0){
                            this.orderInfo = res.data.data || {};
                            this.orderInfo.discount_amount = 0;
                            this.vip_info = res.data.data.vip_info[0] || {};
                            this.payway = this.payWayList[this.orderInfo.pay_way];
                        }
                    })
                }else if(this.order_type == 3){ //套餐订单
                    this.$axios.Package_orderInfo(params).then(res => {
                        // console.log(res.data);
                        if(res.data.code == 0){
                            this.orderInfo = res.data.data || {};
                            this.orderInfo.discount_amount = 0;
                            // this.vip_info = res.data.data.vip_info[0] || {};
                            this.payway = this.payWayList[this.orderInfo.pay_way];
                            this.scene = this.sceneList[this.orderInfo.auth_scene];
                            this.authTime = this.authTimeList[this.orderInfo.auth_time];
                            this.area = this.areaList[this.orderInfo.auth_area];
                        }
                    })
                }
                

                
            },
        },

    }
</script>

<style lang="scss" scoped>
    .user-wrapper {
        width: 100%;
        padding-top: 24px;
        background: #F9FAFA;
        vertical-align: top;
    }

    .order_info {
        min-height: 729px;
        /* background: #FFFFFF; */
        width: 100%;
        /* padding-top: 20px; */
        padding: 21px 31px;

        .desc {
            height: 170px;
            border: 1px solid #EBF0F2;
            padding-top: 24px;
            color: rgba(0, 0, 0, 0.85);
            background-color: #FFFFFF;
        }
    }

    .order_head,
    .order_bottom {
        width: 100%;
        background: #F4F6F6;
        border: 1px solid #EBF0F2;
        line-height: 46px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        padding: 0 24px;
    }

    .order_bottom {
        margin-top: 24px;
    }

    .bottom_desc {
        border: 1px solid #EBF0F2;
        padding: 24px;
    }
</style>